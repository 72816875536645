import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'antd';

const columns = [
  {
    title: 'Service Type',
    dataIndex: 'service_type',
    onHeaderCell: () => ({
      style: { color: '#000', fontWeight: 'bolder',fontSize:18 }, // Light orange
    }),
    render: (text) => (
      <div style={{ color: '#7d2d38', fontFamily: 'Times New Roman',fontSize:18 }}>{text}</div> // Light blue
    ),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    onHeaderCell: () => ({
      style: { color: '#000', fontWeight: 'bolder',fontSize:18 }, // Light orange
    }),
    render: (text) => (
      <div style={{ color: '#38761d', fontFamily: 'Times New Roman',fontSize:18 }}>{text}</div> // Light pink
    ),
  },
  {
    title: 'Distance',
    dataIndex: 'distance',
    onHeaderCell: () => ({
      style: { color: '#000', fontWeight: 'bolder',fontSize:18 }, // Light orange
    }),
    render: (text) => (
      <div style={{ color: '#0000ff', fontFamily: 'Times New Roman',fontSize:18 }}>{text}</div> // Light yellow
    ),
  },
];

export default function PricingModal({ modalStatus, onCancel, modalData, distance = '' }) {
  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    if (modalData) {
      setUpdatedData(
        modalData.map((item, index) => ({
          ...item,
          price: `$ ${item.price}`,
          distance: `${distance} km`,
        }))
      );
    }
  }, [modalData, distance]);

  return (
    <Modal width={1000} footer={false} centered visible={modalStatus} onCancel={onCancel}>
      <div className='mt-5'>
        <Table
          style={{ padding: 0, fontWeight: 1000 }}
          columns={columns}
          dataSource={updatedData}
          pagination={false} // Optional: Disable pagination for simplicity
        />
        <p style={{ color: '#0000ff', fontFamily: 'Times New Roman',fontSize:18,fontWeight: 'bolder',marginTop:20,textAlign:"center" }}>Rush orders are also available in 90 mins, 2hrs, 3hrs, and 4 hours</p>
      </div>
    </Modal>
  );
}
